class SideBarHelpers {
  setStyle(element, theme) {
    element.style.background = theme === 'default' ? 'rgba(255, 255, 255, 0.05)' : '';
    element.firstChild.style.background = theme === 'default' ? '' : '#e74a39';
    element.style.color = '#fff';
    element.firstChild.style.color = '#fff';
    element.firstChild.style.opacity = 1;
    element.style.opacity = 1;
  }

  resetStyle(element) {
    element.style.background = 'transparent';
    element.firstChild.style.background = '';
    element.style.color = '';
    element.firstChild.style.color = '';
    if (element.firstChild.nodeName === 'IMG') {
      element.firstChild.style.opacity = 0.4;
    }
  }

  setDefaultStyle(element) {
    if (element.firstChild.nodeName === 'IMG') {
      element.firstChild.style.background = '';
      element.style.background = '';
      element.style.color = '';
      element.firstChild.style.color = '';
      element.firstChild.style.opacity = '';
    }
  }

  setActiveLink(route, theme) {
    const div = [...document.getElementsByClassName('c-sidebar-nav-link')];

    div.forEach(element => {
      if (element.classList.contains('router-link-active')) {
        element.firstChild.style.background = theme === 'default' ? '' : '#e74a39';
      }

      if (element.hash !== '') {
        const ref = element.hash.replace('#/', '');

        if ((route.fullPath.includes(ref))) {
          this.setStyle(element, theme);
        } else {
          this.resetStyle(element);
        }

        if (!element.classList.contains('router-link-active')) {
          this.setDefaultStyle(element);
        }
      }
    });
  }
}

export const sideBarHelpers = new SideBarHelpers();
